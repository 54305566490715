@import '../Sass/variables.scss';

.article {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
  }
  .name{
    font-family: 'Roboto', sans-serif;
    color: $gray;
    font-size: 10px;
    margin: 0px 12px 0px 12px;
  }
  .price {
    font-family: 'Roboto', sans-serif;
    color: $gray;
    font-size: 11px;
    margin: 0px;
  }
}

.line {
  border: 0.1px solid $lightGray;
}