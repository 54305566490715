@import '../Sass/variables.scss';

.footer {
  display: flex;
  justify-content: center;
  background-color: $black;
  color: $white;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 30px;
}