@import '../Sass/variables.scss';

.form {

  form {

    @include media('laptop') {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr; 
    } 
  }

  border: 1px solid lightGray;
  margin: auto;
  padding-bottom: 30px;
  @include media('mobileS') {
    padding-left: 20px;     
    padding-right: 20px; 
    width: 220px;    
  }
  @include media('laptop') {
    padding-left: 70px;     
    padding-right: 70px; 
    width: 600px; 
  }
  h4 {
    text-align: center;
  }
  .img {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    img {
      width: 30px;
      margin-right: 5px;
    }
  }
}