@import '../Sass/variables.scss';

.list {
  &__title {
    position: relative;
    list-style:none;
    margin-top:  10px;
    @include media('mobileS') {
      margin: 5px 30px 5px 30px;           
      padding: 5px 4px 5px 4px;
    }
  }
  &:hover {
    background-color: $black;
    cursor: pointer;
    border-radius: 10px;
    .subItems {
      display: block;
    }
    &:hover {
      color: $orange;
    }
  }
  .subItems {
    display: none;
    position: absolute;
    top: 0px;
    left: 115px;
    padding: 0px;
    &__title {
      list-style:none;
      padding: 5px 17px 5px 17px; 
      border-radius: 10px;
      &:hover {
        background-color: $black;
        color: $orange;
      }
    }
  }
}